// cf. https://symfony.com/doc/current/frontend/encore/simple-example.html#page-specific-javascript-or-css-multiple-entries
// body {
// 	background: red !important;
// }

@import '../variables';

body.home {			
	.left-column, .center-column, .right-column  {
		> div {
			margin-bottom: 2rem;
		}
	}

	.alaune {
        font-size: 1.1rem;
        img.thumbnail {
            margin: 0.3rem 1rem 0rem 0;
			width: 60%;
		}
	}


	.publications, .actualites, .articles-fond {
		ul {
			padding-left: 0.3rem;
			list-style-type: none;
			li a {
				@include truncate;
				i, svg {
					margin-right: 0.4rem;
				}
			}
		}
		// &.publications ul li a {
		// 	@include truncate;
		// }
	}


	.bienvenue p {
		text-align: center;
	}

	.carte {
		a {
			display: inline-block;
			width: 100%;
			img {
				max-height: 250px;
			}
		}
	}

	.themes .theme img {
		max-width: 90px;
	}
}